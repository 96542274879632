import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import Img from "gatsby-image"



const CardTag = styled(Typography)({
  textAlign: 'center',
  color: '#7a7b7b'
});

const CardTitle = styled(Typography)({
  textAlign: 'center',
  color: '#5d5e60',
  fontSize: 16
});




const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 154,
  },
});

export default ({ image, title , category}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>


        <Img
         fluid={image}
         alt={title}
         style={{width: '100%', maxHeight: 150}}
        />

        <CardContent>
          <CardTitle gutterBottom variant="h6" component="h2">
            {title}
          </CardTitle>
          <CardTag variant="subtitle1" gutterBottom>
            {category}
          </CardTag>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
