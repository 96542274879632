import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../layout";
//import PostListing from "../components/PostListing/PostListing";
import config from "../../data/SiteConfig";
import SideNav from "../components/SideNav";
import ProductCard from "../components/Product/ProductCard";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

export default function CategoryTemplate({ pageContext, data }) {
  const { category } = pageContext;
  const productEdges = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <Helmet title={`Posts tagged as "${category}" | ${config.siteTitle}`} />

      <Container style={{ padding: "50px 15px" }}>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12} sm={12} spacing={1}>
            <SideNav />
          </Grid>

          <Grid item md={9} xs={12} sm={12} spacing={1}>
            <Grid container spacing={1}>
              {productEdges.map((product, index) => (
                <Grid item md={4} xs={12} sm={6} key={index}>
                  <Link to={`/product${product.node.fields.slug}`}>
                    <ProductCard
                      image={
                        product.node.frontmatter.cover.childImageSharp.fluid
                      }
                      title={product.node.frontmatter.title}
                      category={product.node.frontmatter.category}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
            category
          }
        }
      }
    }
  }
`;
