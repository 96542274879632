import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "gatsby";

import BrowseIcon from "./../Icons/Browse";

const navData = [
  {
    id: 1,
    title: "كل المنجات ",
    url: "/",
  },
  {
    id: 2,
    title: "بطاطس مجمدة",
    url: "/categories/بطاطس-مجمدة",
  },
  {
    id: 3,
    title: "خضروات مجمدة",
    url: "/categories/خضروات-مجمدة",
  },
  {
    id: 4,
    title: "فاكهة مجمدة",
    url: "/categories/فاكهة-مجمدة",
  },
];

const ListHeader = styled(Typography)({
  background: "#80bb01",
  color: "white",
  padding: 15,
});

export default () => {
  //const classes = useStyles();
  return (
    <Paper>
      <ListHeader variant="h5" gutterBottom>
        <BrowseIcon color="white" size="25px" />
        التصنيفات
      </ListHeader>
      <List>
        {navData.map((nav, index) => (
          <Link to={nav.url}>
            <ListItem button key={index}>
              <ListItemIcon>
                <span style={{ color: "#80bb10" }}> » </span>
              </ListItemIcon>
              <ListItemText primary={nav.title} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Paper>
  );
};
