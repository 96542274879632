import React from "react";

// <Browse color="" size=""/>
export default (props)=> {
  const{color, size } = props;
  return (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
  >
    <rect
      width="80"
      height="80"
      x="64"
      y="64"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
    <rect
      width="80"
      height="80"
      x="216"
      y="64"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
    <rect
      width="80"
      height="80"
      x="368"
      y="64"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
    <rect
      width="80"
      height="80"
      x="64"
      y="216"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
    <rect
      width="80"
      height="80"
      x="216"
      y="216"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
    <rect
      width="80"
      height="80"
      x="368"
      y="216"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
    <rect
      width="80"
      height="80"
      x="64"
      y="368"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
    <rect
      width="80"
      height="80"
      x="216"
      y="368"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
    <rect
      width="80"
      height="80"
      x="368"
      y="368"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="32"
      rx="40"
      ry="40"
    ></rect>
  </svg>
);
}
